import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import { IconCollection } from '../../../../components';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    sectionId,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const HeroBlockId =
    blockId == 'hero-block-1' ||
    blockId == 'hero-block-2' ||
    blockId == 'hero-block-3' ||
    blockId == 'hero-block-4';

  const CategoryBlockId =
    blockId == 'tops-category-1' ||
    blockId == 'bottoms-category' ||
    blockId == 'bags-category' ||
    blockId == 'dresses-category' ||
    blockId == 'shoes-category' ||
    blockId == 'accessories-category' ||
    blockId == 'coats-category' ||
    blockId == 'suits-category' ||
    blockId == 'swim-category' ||
    blockId == 'jewlery-category' ||
    blockId == 'other-category' ||
    blockId == 'all-category';

  return (
    <BlockContainer id={blockId} className={classes}>
      {CategoryBlockId ? (
        <div className={css.media}>
          <a href={callToAction?.href} rel="noopener noreferrer">
            <IconCollection icon={title.content} />
          </a>
        </div>
      ) : (
        <FieldMedia
          media={media}
          sizes={responsiveImageSizes}
          className={mediaClassName}
          options={options}
        />
      )}

      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          {HeroBlockId ? (
            <div className={css.textWrapper}>
              {' '}
              <a href={callToAction?.href} rel="noopener noreferrer">
                {' '}
                <span className={css.iconWrapper}>
                  <IconCollection
                    icon={
                      title?.content == 'CLOTHING'
                        ? 'iconClothingLg'
                        : title.content == 'SNEAKERS'
                        ? 'iconSneakersLg'
                        : title.content == 'STREETWEAR'
                        ? 'iconSTreetWearLg'
                        : 'iconVintageLg'
                    }
                  />
                </span>{' '}
                <div className={css.titleWrapper}>
                  {' '}
                  {/* <span className={css.buyText}>BUY</span> */}
                  <Field data={title} options={options} />
                </div>
              </a>
            </div>
          ) : (
            <Field data={title} options={options} />
          )}

          <Field data={text} options={options} />
          {HeroBlockId || CategoryBlockId ? null : (
            <Field data={callToAction} className={ctaButtonClass} options={options} />
          )}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
