import React, { useEffect } from 'react';
import { arrayOf, bool, func, node, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
// Section components
import SectionArticle from './SectionArticle';
import SectionCarousel from './SectionCarousel';
import SectionColumns from './SectionColumns';
import SectionFeatures from './SectionFeatures';
import Slider from 'react-slick';
import { IconCollection, ListingCard, NamedLink, PaginationLinks, Skeleton } from '../../../components';
import Markdown from 'markdown-to-jsx';
// Styles
// Note: these contain
// - shared classes that are passed as defaultClasses
// - dark theme overrides
// TODO: alternatively, we could consider more in-place way of theming components
import css from './SectionBuilder.module.css';
import ProductSlider from './productSlider';
import HeroSlider from './HeroSlider';
import { getCategoryArray } from '../../../util/dataExtractor';
import { FormattedMessage } from 'react-intl';
import { parse } from '../../../util/urlHelpers';
import renderMarkdown from '../markdownProcessor';
import { ALL_GENDER, CLOTHING, MEN_GENDER, WOMEN_GENDER } from '../../../util/types';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';
import { category, sneakersSubCategory } from '../../../config/marketplace-custom-config';
import moment from 'moment';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        style={{
          transform: 'rotate(178deg)',
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}

// These are shared classes.
// Use these to have consistent styles between different section components
// E.g. share the same title styles
const DEFAULT_CLASSES = {
  sectionDetails: css.sectionDetails,
  title: css.title,
  description: css.description,
  ctaButton: css.ctaButton,
  blockContainer: css.blockContainer,
};

/////////////////////////////////////////////
// Mapping of section types and components //
/////////////////////////////////////////////

const defaultSectionComponents = {
  article: { component: SectionArticle },
  carousel: { component: SectionCarousel },
  columns: { component: SectionColumns },
  features: { component: SectionFeatures },
};

//////////////////////
// Section builder //
//////////////////////

const SectionBuilder = props => {
  const {
    sections,
    options,
    pageListings,
    onActivateListing,
    params,
    history,
    CategorySelected,
    pagination,
    blogsData = [],
    heroSectionData,
    recentlyViewPageListings,
    currentUser,
  } = props;

  const { recentlyViewedItems } = currentUser?.attributes?.profile?.publicData || {};

  recentlyViewPageListings?.sort((a, b) => {
    const dateA = moment(a.attributes.publicData.updatedTime, 'DD-MM-yyyy HH:mm:ss');
    const dateB = moment(b.attributes.publicData.updatedTime, 'DD-MM-yyyy HH:mm:ss');
    return dateB.diff(dateA);
  });  

  const { sectionComponents = {}, isInsideContainer, ...otherOption } = options || {};

  useEffect(() => {
    CategorySelected && localStorage.setItem('localStore', JSON.stringify(CategorySelected));
  }, [CategorySelected]);

  // If there's no sections, we can't render the correct section component
  if (!sections || sections.length === 0) {
    return null;
  }

  // Selection of Section components
  const components = { ...defaultSectionComponents, ...sectionComponents };
  const getComponent = sectionType => {
    const config = components[sectionType];
    return config?.component;
  };
  const isMapVariant = true;
  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const CategorySettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const pathname = params && params.type;
  const capitalizedPathname = pathname?.toUpperCase();

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="LandingPage"
        pageSearchParams={parse(history.location.search)}
        pagination={pagination}
      />
    ) : null;
  const gender = params && params?.gender ;
  const filteredCategory = gender === MEN_GENDER || gender === WOMEN_GENDER ? category?.length &&
    category?.filter(
      e => e.genderKey?.includes(gender)
    ) : category?.filter((e)=>e.key !== 'suits-tuxedos');
  return (
    <>
      {sections.map((section, index) => {
        const Section = getComponent(section.sectionType);
        // If the default "dark" theme should be applied (when text color is white).
        // By default, this information is stored to customAppearance field
        const isDarkTheme = section?.appearance?.textColor === 'white';
        const classes = classNames({ [css.darkTheme]: isDarkTheme });

        if (Section) {
          return (
            <div
              key={section?.sectionId}
              onClick={() => {
                CategorySelected &&
                  localStorage.setItem('localStore', JSON.stringify(CategorySelected));
              }}
            >
              {(CategorySelected && section.sectionId == 'hero' || section.sectionId === 'shop-by-category') ? null : section.sectionId ==
                'articles-section' || section.sectionId === 'shop-by-category' ? (
                <>
                  <div className={css.SectionArticles}>
                    <div className={css.sectionHeader}>
                      <h2>
                        <FormattedMessage id="LandingPage.categoryHeading" />
                      </h2>
                    </div>
                    <div className={css.categoryItems}>
                    <Slider {...CategorySettings}>
                      {(CategorySelected === 'sneakers' ? sneakersSubCategory: filteredCategory).map(category => (
                        <div
                          key={category.key}
                          className={css.cartegoryItem}
                          onClick={() => {
                            const routes = routeConfiguration();
                            history.push(
                              createResourceLocatorString(
                                'SearchPage',
                                routes,
                                {},
                                {
                                  pub_category:  (category.key === 'jewelry' && 
                                  (gender === WOMEN_GENDER || !gender || gender=== ALL_GENDER)) || 
                                  CategorySelected !== 'sneakers' ? 
                                  getCategoryArray(category, gender) 
                                  : null,
                                  pub_subCategory:CategorySelected === 'sneakers' ? category.key : (category.key === 'jewelry' && (gender === MEN_GENDER || !gender || gender === ALL_GENDER)) ? 'jewelry-watches':null,
                                  pub_gender: category.label === 'Men Shoes' ? 'men' : category.label === 'Women Shoes'? 'women' : gender !== ALL_GENDER ? gender : null,
                                  pub_listingStore: CategorySelected ? CategorySelected : null,
                                }                               
                              )
                            );
                          }}
                        >
                          <div className={css.media}>
                            <IconCollection icon={category.label} />
                          </div>
                          <span className={css.text} >{category.label}</span>

                        </div>
                      ))}
                       </Slider>
                    </div>
                  </div>
                  {blogsData?.length > 0  ? <div className={css.SectionArticles}>
                    <div className={css.sectionHeader}>
                      <h2>
                        <FormattedMessage id="LandingPage.blogHeading" />
                      </h2>
                    </div>
                    <div className={css.articlesItem}>
                      {blogsData?.length > 0 &&
                        blogsData?.map(blog => {
                          return (
                            <div className={css.mainBlog} key={uuidv4()}>
                              <NamedLink name="BlogPage">
                                <img
                                  src={blog?.attributes?.image?.data?.attributes.url}
                                  alt="blog-image"
                                />
                                <Markdown>{blog?.attributes?.data}</Markdown>
                              </NamedLink>
                            </div>
                          );
                        })}
                    </div>
                  </div> : null}
                </>
              ) : (
                <Section
                  key={`${section.sectionId}_${index}`}
                  className={classes}
                  defaultClasses={DEFAULT_CLASSES}
                  isInsideContainer={isInsideContainer}
                  options={otherOption}
                  sectionId={section.sectionId}
                  {...section}
                />
              )}

              {CategorySelected && section.sectionId == 'hero' && (
                <div className={css.HeroSLiderWrapper}>
                  <HeroSlider
                    CategorySelected={CategorySelected}
                    heroSectionData={heroSectionData}
                  />
                </div>
              )}
              {section.sectionId == 'hero' &&
                recentlyViewPageListings &&
                recentlyViewPageListings.length ? (
                <div className={css.ListingCards}>
                  <div className={css.sectionHeader}>
                    <h1 className={classNames(CategorySelected && css.titleWithShadow)}>
                      <FormattedMessage id="LandingPage.recentViewText" />
                    </h1>
                    <NamedLink
                      className={css.viewAllBtn}
                      name={
                        CategorySelected ? 'RecentViewListingsPage' : 'RecentViewAllListingsPage'
                      }
                      params={{ listingStore: CategorySelected && CategorySelected }}
                    >
                      <FormattedMessage id="ListingPage.viewAll" />
                    </NamedLink>
                  </div>
                  <ProductSlider
                    className={css.listingCard}
                    CategorySelected={CategorySelected}
                    pageListings={recentlyViewPageListings}
                    renderSizes={cardRenderSizes(isMapVariant)}
                    setActiveListing={onActivateListing}
                  />
                </div>
              ) : null}
              {section.sectionId == 'hero' && pageListings && pageListings.length ? (
                <div className={css.ListingCards}>
                  <div className={css.sectionHeader}>
                    <h1 className={classNames(CategorySelected && css.titleWithShadow)}>
                      NEW {params && params.type ? capitalizedPathname : ''} LISTINGS
                    </h1>
                    <div
                      className={css.viewAllBtn}
                      onClick={() => {
                        const routes = routeConfiguration();
                        history.push(
                          createResourceLocatorString(
                            'SearchPage',
                            routes,
                            {},
                            {
                              pub_listingStore: CategorySelected,
                              pub_gender: gender === ALL_GENDER ? null : gender,
                            }
                          )
                        );
                      }}
                    >
                      <FormattedMessage id="ListingPage.viewAll" />
                    </div>
                  </div>
                  <ProductSlider
                    className={css.listingCard}
                    CategorySelected={CategorySelected}
                    pageListings={pageListings}
                    renderSizes={cardRenderSizes(isMapVariant)}
                    setActiveListing={onActivateListing}
                  />
                </div>
              ) : null}

              {/* {paginationLinks} */}
            </div>
          );
        } else {
          // If the section type is unknown, the app can't know what to render
          console.warn(`Unknown section type (${section.sectionType}) detected.`);
          return null;
        }
      })}
    </>
  );
};

const propTypeSection = shape({
  sectionId: string.isRequired,
  sectionType: oneOf(['article', 'carousel', 'columns', 'features']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }),
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const defaultSections = shape({
  sections: arrayOf(propTypeSection),
  options: propTypeOption,
});

const customSection = shape({
  sectionId: string.isRequired,
  sectionType: string.isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});
const propTypeOptionForCustomSections = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }).isRequired,
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const customSections = shape({
  sections: arrayOf(customSection),
  options: propTypeOptionForCustomSections.isRequired,
});

SectionBuilder.defaultProps = {
  sections: [],
  options: null,
};

SectionBuilder.propTypes = oneOf([defaultSections, customSections]).isRequired;

export default SectionBuilder;
