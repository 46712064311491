import React from 'react';

import classNames from 'classnames';

import Slider from 'react-slick';
// import SliderImg1 from '../../../assets/hero_slider/hero_slider_1.png';
// import SliderRight1 from '../../../assets/hero_slider/hero_slider_right_1.png';
// import SliderImg2 from '../../../assets/hero_slider/hero_slider_2.png';
// import SliderImg3 from '../../../assets/hero_slider/hero_slider_3.png';
// import SliderImg4 from '../../../assets/hero_slider/hero_slider_4.png';
// import SliderRight2 from '../../../assets/hero_slider/hero_slider_right_2.png';
// import SliderRight3 from '../../../assets/hero_slider/hero_slider_right_3.png';
// import SliderRight4 from '../../../assets/hero_slider/hero_slider_right_4.png';

// import SliderSneakerImg1 from '../../../assets/hero_slider/hero_slider_sneaker_1.png';
// import SliderSneakerRight1 from '../../../assets/hero_slider/hero_slider_right_sneaker_1.png';
// import SliderSneakerImg2 from '../../../assets/hero_slider/hero_slider_sneaker_2.png';
// import SliderSneakerRight2 from '../../../assets/hero_slider/hero_slider_right_sneaker_2.png';
// import SliderSneakerImg3 from '../../../assets/hero_slider/hero_slider_sneaker_3.png';
// import SliderSneakerRight3 from '../../../assets/hero_slider/hero_slider_right_sneaker_3.png';

// import SliderstreetwearImg1 from '../../../assets/hero_slider/hero_slider_streetwear_1.png';
// import SliderstreetwearRight1 from '../../../assets/hero_slider/hero_slider_right_streetwear_1.png';
// import SliderstreetwearImg2 from '../../../assets/hero_slider/hero_slider_streetwear_2.png';
// import SliderstreetwearRight2 from '../../../assets/hero_slider/hero_slider_right_streetwear_2.png';
// import SliderstreetwearImg3 from '../../../assets/hero_slider/hero_slider_streetwear_3.png';
// import SliderstreetwearRight3 from '../../../assets/hero_slider/hero_slider_right_streetwear_3.png';

// import SlidervintageImg1 from '../../../assets/hero_slider/hero_slider_vintage_1.png';
// import SlidervintageRight1 from '../../../assets/hero_slider/hero_slider_right_vintage_1.png';
// import SlidervintageImg2 from '../../../assets/hero_slider/hero_slider_vintage_2.png';
// import SlidervintageRight2 from '../../../assets/hero_slider/hero_slider_right_vintage_2.png';
// import SlidervintageImg3 from '../../../assets/hero_slider/hero_slider_vintage_3.png';
// import SlidervintageRight3 from '../../../assets/hero_slider/hero_slider_right_vintage_3.png';

import css from './SectionBuilder.module.css';
import { Button, IconCollection, NamedLink } from '../../../components';
import { getCategoryItems } from '../../../util/dataExtractor';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const HeroSlider = props => {
  const { CategorySelected, heroSectionData } = props;
  const { clothing, sneakers, streetwear, vintage } = heroSectionData || {};
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderImage = (item) => {
    if (!item || !item.attributes || !item.attributes.sideImage || !item.attributes.sideImage.data) {
      return null;
    }
  
    const imageUrl = item.attributes.sideImage.data.attributes.url;
  
    return (
      <img src={imageUrl} alt="slider-img" className="image-class" onError={(e) => {
        e.target.onerror = null;
        e.target.src = 'placeholder-image-url';
      }} />
    );
  };

  return (
    <div className={css.HeroSlider}>
      <div className={css.sliderLeft}>
        <Slider {...settings}>
          {getCategoryItems(CategorySelected, vintage, streetwear, sneakers, clothing).map(
            (item, i) => (
              <div className={css.sliderImageWrapper} key={i}>
                <div>
                  <img src={item.attributes.mainImage.data.attributes.url} alt="slider-img" />
                </div>
                <div className={css.sliderContent}>
                  <h4>{item.attributes.title}</h4>
                  <NamedLink name="SearchPage">
                    <Button>
                      <IconCollection icon="SearchWhiteIcon" /> Discover Now
                    </Button>
                  </NamedLink>
                </div>
              </div>
            )
          )}
        </Slider>
      </div>
      <div className={css.sliderRight}>
        <Slider {...settings2}>
          {getCategoryItems(CategorySelected, vintage, streetwear, sneakers, clothing).map(
            (item, i) => (
              <div key={i}>
                {renderImage(item)}
              </div>
            )
          )}
        </Slider>
      </div>
    </div>
  );
};

export default HeroSlider;
