import React, { useEffect } from 'react';

import { Footer as FooterContent, IconCollection, Modal, Skeleton, NamedLink, Menu, MenuLabel, MenuContent, MenuItem } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';

import { validProps } from './Field';

import LayoutComposer from './LayoutComposer/index.js';
import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import css from './PageBuilder.module.css';
import { listingStores } from '../../config/marketplace-custom-config.js';
import routeConfiguration from '../../routing/routeConfiguration.js';
import { createResourceLocatorString } from '../../util/routes.js';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  ALL_GENDER,
  CLOTHING,
  MEN_GENDER,
  SNEAKERS,
  STREETWEAR,
  VINTAGE,
  WOMEN_GENDER,
} from '../../util/types.js';
import { isEmailVerified, localBagData } from '../../util/dataExtractor.js';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    pageListings,
    onActivateListing,
    history,
    pagination,
    blogsData,
    params,
    heroSectionData,
    searchInProgress,
    pageInProgress,
    recentlyViewPageListings,
    currentUser,
    currentUserHasListings,
    ...pageProps
  } = props;
  const emailVerified = currentUser?.id && isEmailVerified(currentUser);
  const { sellerShippingAddress = [] } =
  (!!currentUser?.id && currentUser?.attributes.profile.protectedData) || {};
  
  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }
  const addressVerificationNeeded = sellerShippingAddress.length === 0 && currentUserHasListings && emailVerified;

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const CategorySelected = params && params.type;
  useEffect(() => {
    if (CategorySelected === CLOTHING) {
      document.documentElement.style.setProperty('--marketplaceColor', '#478cbc');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#3065a1');
      document.documentElement.style.setProperty('--matterColorNegative', '#f8f7f7');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#238DC1');

      document.documentElement.style.setProperty('--matterColorBright', '#edf5fa');
    } else if (CategorySelected === SNEAKERS) {
      document.documentElement.style.setProperty('--marketplaceColor', '#D7282F');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#D7282F');
      document.documentElement.style.setProperty('--matterColorNegative', '#F3E6E7');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#D7282F');
      document.documentElement.style.setProperty('--matterColorBright', '#F3E6E7');
    } else if (CategorySelected === STREETWEAR) {
      document.documentElement.style.setProperty('--marketplaceColor', '#E87200');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#E87200');
      document.documentElement.style.setProperty('--matterColorNegative', '#FEF4EA');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#E87200');
      document.documentElement.style.setProperty('--matterColorBright', '#edf5fa');
    } else if (CategorySelected === VINTAGE) {
      document.documentElement.style.setProperty('--marketplaceColor', '#61A60E');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#61A60E');
      document.documentElement.style.setProperty('--matterColorNegative', '#F2FAE9');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#61A60E');
      document.documentElement.style.setProperty('--matterColorBright', '#edf5fa');
    } else {
      document.documentElement.style.setProperty('--marketplaceColor', '#478cbc');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#3065a1');
      document.documentElement.style.setProperty('--matterColorNegative', '#f8f7f7');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#121313');
      document.documentElement.style.setProperty('--matterColorBright', '#F1F0F0');

    }
  }, [CategorySelected]);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  const lb = localBagData();

  const loadingResults = (
    <div>
      {CategorySelected && (
        <div className={css.ProductSliderSkeleton}>
          <Skeleton width="60%" height={'400px'} />
          <Skeleton width="40%" height={'400px'} />
        </div>
      )}

      {!CategorySelected && (
        <div
          className={css.ProductCardSkeletonWrapper}
          style={{ marginBottom: '60px', marginTop: '30px' }}
        >
          <div className={css.ListingCardsSkeleton}>
            <div className={css.listingCard}>
              <Skeleton width="100%" height={'425px'} />
            </div>
            <div className={css.listingCard}>
              <Skeleton width="100%" height={'425px'} />
            </div>
            <div className={css.listingCard}>
              <Skeleton width="100%" height={'425px'} />
            </div>
            <div className={css.listingCard}>
              <Skeleton width="100%" height={'425px'} />
            </div>
          </div>
        </div>
      )}

      <div className={css.ProductCardSkeletonWrapper} style={{ marginBottom: '60px' }}>
        <div className={css.skeletonTitle}>
          <Skeleton width="10%" height={'15px'} />
        </div>
        <div className={css.ListingCardsSkeleton}>
          <div className={css.listingCard}>
            <Skeleton width="100%" height={'200px'} />
            <Skeleton width="100%" height={'15px'} />
            <Skeleton width="100%" height={'15px'} />
          </div>
          <div className={css.listingCard}>
            <Skeleton width="100%" height={'200px'} />
            <Skeleton width="100%" height={'15px'} />
            <Skeleton width="100%" height={'15px'} />
          </div>
          <div className={css.listingCard}>
            <Skeleton width="100%" height={'200px'} />
            <Skeleton width="100%" height={'15px'} />
            <Skeleton width="100%" height={'15px'} />
          </div>
          <div className={css.listingCard}>
            <Skeleton width="100%" height={'200px'} />
            <Skeleton width="100%" height={'15px'} />
            <Skeleton width="100%" height={'15px'} />
          </div>
        </div>
      </div>

      <div className={css.ProductCardSkeletonWrapper}>
        <div className={css.skeletonTitle}>
          <Skeleton width="20%" height={'15px'} />
        </div>
        <div className={css.categoryCardsSkeleton}>
          {[...Array(12)].map((item, i) => (
            <div className={css.listingCard} key={i}>
              <Skeleton width="100%" height={'195px'} />
              <Skeleton width="100%" height={'15px'} />
            </div>
          ))}
        </div>
      </div>

      <div className={css.ProductCardSkeletonWrapper} style={{ marginTop: '60px' }}>
        <div className={css.skeletonTitle}>
          <Skeleton width="20%" height={'15px'} />
        </div>
        <div className={css.articleCardsSkeleton}>
          <div className={css.listingCard}>
            <Skeleton width="100%" height={'250px'} />
            <Skeleton width="100%" height={'15px'} />
          </div>
          <div className={css.listingCard}>
            <Skeleton width="100%" height={'250px'} />
            <Skeleton width="100%" height={'15px'} />
          </div>
          <div className={css.listingCard}>
            <Skeleton width="100%" height={'250px'} />
            <Skeleton width="100%" height={'15px'} />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <StaticPage {...pageMetaProps} {...pageProps} CategorySelected={CategorySelected}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer activeCategory={CategorySelected} lb={lb} params={params} />
              </Topbar>
              <Main as="main" className={css.main}>
                {CategorySelected ? (
                  <div className={css.seectionWrapper}>
                    <div className={css.CategoryresultsFound}>
                      <div className={css.category}>
                        {listingStores.map(st => {
                          return (
                            <div
                              key={st.key}
                              onClick={() => {
                                const routes = routeConfiguration();
                                history.push(
                                  createResourceLocatorString(
                                    'LandingPageListingStore',
                                    routes,
                                    {
                                      type: `${st.key}`,
                                      gender: ALL_GENDER,
                                    },
                                    {}
                                  )
                                );
                              }}
                            >
                              <div
                                className={classNames(
                                  css.iconWrapper,
                                  CategorySelected == st.key && css.activeStore
                                )}
                              >
                                <span className={css.icon}>
                                  {CategorySelected == st.key ? (
                                    <IconCollection icon={st.key} />
                                  ) : (
                                    <img src={st.icon} alt="icon" />
                                  )}
                                </span>
                                <span> {st.label}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className={css.tabs}>
                        <div
                          className={params.gender === ALL_GENDER ? css.test : css.tabItems}
                          onClick={() => {
                            const routes = routeConfiguration();
                            history.push(
                              createResourceLocatorString(
                                'LandingPageListingStore',
                                routes,
                                { type: params.type, gender: ALL_GENDER },
                                {}
                              )
                            );
                          }}
                        >
                          <FormattedMessage id="LandingPage.allText" />
                        </div>
                        <div
                          className={params.gender === MEN_GENDER ? css.test : css.tabItems}
                          onClick={() => {
                            const routes = routeConfiguration();
                            history.push(
                              createResourceLocatorString(
                                'LandingPageListingStore',
                                routes,
                                { type: params.type, gender: MEN_GENDER },
                                {}
                              )
                            );
                          }}
                        >
                          <FormattedMessage id="LandingPage.menText" />
                        </div>
                        <div
                          className={params.gender === WOMEN_GENDER ? css.test : css.tabItems}
                          onClick={() => {
                            const routes = routeConfiguration();
                            history.push(
                              createResourceLocatorString(
                                'LandingPageListingStore',
                                routes,
                                { type: params.type, gender: WOMEN_GENDER },
                                {}
                              )
                            );
                          }}
                        >
                          <FormattedMessage id="LandingPage.womenText" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <SectionBuilder
                  blogsData={blogsData}
                  pagination={pagination}
                  sections={sections}
                  options={options}
                  pageListings={pageListings}
                  onActivateListing={onActivateListing}
                  CategorySelected={CategorySelected}
                  history={history}
                  params={params}
                  recentlyViewPageListings={recentlyViewPageListings}
                  searchInProgress={searchInProgress}
                  heroSectionData={heroSectionData}
                  currentUser={currentUser}
                />
                <Modal
                  id="LandingPage.address"
                  isOpen={addressVerificationNeeded}
                  onClose={() => { }}
                  usePortal
                  onManageDisableScrolling={() => { }}
                  className={css.modalShipping}
                >
                  <div>
                    <h1 className={css.modalTitle}><FormattedMessage id="LandingPage.heading" /></h1>
                    <div className={css.modalDetails}>
                      <span className={css.iconStore}>
                        <Menu>
                          <MenuLabel
                            className={css.menuLabel}
                          >
                            <IconCollection icon={'storeIcon'} />
                          </MenuLabel>
                          <MenuContent rootClassName={css.menuContentVacation}>
                            <MenuItem key="LandingPage">
                              <FormattedMessage id="LandingPage.infoText" />
                            </MenuItem>
                          </MenuContent>
                        </Menu>
                      </span>
                      <p className={css.Modallink}>
                        <NamedLink name="ShippingAddressDetailPage" >
                          <FormattedMessage id="LandingPage.linkText" />
                          <IconCollection icon="IconButtonRightLong" />
                        </NamedLink>
                      </p>
                    </div>

                  </div>
                </Modal>
                {pageInProgress && loadingResults}
              </Main>
              <Footer>
                <FooterContent isLanding={true} CategorySelected={CategorySelected} />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { StaticPage, SectionBuilder };

export default PageBuilder;
